<template>
  <CContainer>
    <CRow class="justify-content-center mt-4">
      <CCol md="12" class="text-start">
        <div class="d-flex align-items-center">
          <h2>Kiosk</h2>
          <div class="d-flex align-items-center ms-4">
            <span class="fw-bold me-2">Off</span>
            <CFormSwitch
              data-test-id="kiosk-switch"
              class="modules-switch mx-1"
              shape="pill"
              :checked="isActive"
              @update:model-value="toggleKiosk(isActive)"
            />
            <span class="fw-bold ms-2">On</span>
          </div>
          <HelpCenterButton classes="ms-2" content-key="module_kiosk" />
        </div>
        <div v-if="isActive">
          <CFormCheck
            label="Enable URL Klogin Method Only (highly recommended for use with Chromebooks)"
            v-model="form.urlKloginMethodOnly"
            class="custom-checkbox text-start"
            :custom="true"
            @update:model-value="submit"
          />

          <CFormCheck
            label="Card As Default Login"
            v-model="form.cardAsDefaultLogin"
            class="custom-checkbox text-start"
            :custom="true"
            @update:model-value="checkCard"
          />

          <CFormCheck
            label="Barcode As Default Login"
            v-model="form.barcodeAsDefaultLogin"
            class="custom-checkbox text-start"
            :custom="true"
            @update:model-value="checkBarcode"
          />
          <CFormCheck
            label="Hide Student Kiosk Password Field"
            v-model="form.hideStudentKioskPasswordField"
            class="custom-checkbox text-start"
            :custom="true"
            @update:model-value="submit"
          />
          <div class="d-flex align-items-center">
            <CFormCheck
              label="Set Kiosk Password to be Student Number"
              v-model="form.studentNumberAsKioskPassword"
              class="custom-checkbox text-start"
              :custom="true"
              @update:model-value="openKiossPasswordModal"
            />
            <span class="small mt-1 ms-2">
              (Student Number must be 4+ characters long)</span
            >
          </div>
          <CModal
            backdrop="static"
            :visible="kiossPasswordModal"
            :close-on-backdrop="true"
          >
            <div
              class="pt-4 px-4 create-popup-header d-flex align-items-center justify-content-between"
            >
              <div
                class="d-inline-block bg-gradient-blue text-white rounded-circle p-1 lh-normal cursor-pointer"
                @click="
                  () => {
                    kiossPasswordModal = false
                    form.studentNumberAsKioskPassword = false
                  }
                "
              >
                <span class="close-icon" />
              </div>
            </div>
            <div class="px-3">
              <h4 class="mb-4 text-center">
                This will overwrite any other password that has already been
                created for all Kiosk users. Are you sure you want to proceed?
              </h4>
              <div class="d-flex flex-wrap w-100 justify-content-center">
                <button
                  class="btn btn-cencel m-2 border-1 border-secondary"
                  @click="
                    () => {
                      submit()
                      kiossPasswordModal = false
                    }
                  "
                >
                  <span class="flaticon-check me-1 text-black" />
                  Yes, overwrite all passwords with a student number.
                </button>
                <button
                  class="btn btn-delete m-2"
                  @click="
                    () => {
                      kiossPasswordModal = false
                      form.studentNumberAsKioskPassword = false
                    }
                  "
                >
                  <span class="flaticon-cancel me-2" /> No
                </button>
              </div>
            </div>
            <template #footer-wrapper>
              <div />
            </template>
          </CModal>
          <CRow class="mt-2 mb-6">
            <p class="mt-2 text-start ms-3">Default Domain for Kiosk:</p>
            <CCol md="6">
              <VSelect
                v-model="form.defaultDomain"
                class="cs-select ps__child--consume"
                :options="schoolDomains"
                placeholder="Default Domain"
                :clearable="false"
                :searchable="false"
                @update:model-value="submit"
              />
            </CCol>
          </CRow>
          <CAlert
            v-if="reqResponse.message"
            class="mt-4"
            :color="reqResponse.type"
            :show="!!reqResponse.message"
          >
            {{ reqResponse.message }}
          </CAlert>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import HelpCenterButton from "../HelpCenterButton"
import { mapState, mapGetters } from "vuex"
import _ from "underscore"

export default {
  name: "KioskModuleForm",
  components: { HelpCenterButton },
  data() {
    return {
      isLoading: false,
      kiossPasswordModal: false,
      form: {
        urlKloginMethodOnly: false,
        cardAsDefaultLogin: false,
        barcodeAsDefaultLogin: false,
        hideStudentKioskPasswordField: false,
        studentNumberAsKioskPassword: false,
        defaultDomain: null
      },
      reqResponse: {
        type: "",
        message: "",
        errors: {}
      },
      isActive: false
    }
  },
  computed: {
    ...mapGetters({
      schoolDomains: "schools/schoolDomains",
      currentUser: "authentication/user"
    }),
    ...mapState({
      options: (state) => {
        const index = _.findIndex(state.schools.activeSchoolModules, {
          name: "Kiosk"
        })
        return index !== -1
          ? JSON.parse(
              state.schools.activeSchoolModules[index].pivot.option_json
            )
          : false
      },
      active: (state) => {
        const index = _.findIndex(state.schools.activeSchoolModules, {
          name: "Kiosk"
        })
        return index !== -1
          ? Boolean(state.schools.activeSchoolModules[index].pivot.status)
          : false
      }
    })
  },
  mounted() {
    this.$store.dispatch("schools/getSchoolDomains", this.currentUser.school_id)
    if (this.options) {
      this.form.urlKloginMethodOnly = Boolean(this.options.kurl)
      this.form.cardAsDefaultLogin = Boolean(this.options.usbcard)
      this.form.barcodeAsDefaultLogin = Boolean(this.options.barcode)
      this.form.hideStudentKioskPasswordField = Boolean(this.options.spassword)
      this.form.studentNumberAsKioskPassword = Boolean(
        this.options.prefill_kiosk_password
      )
      this.form.defaultDomain = this.options.default_domain
    } else {
      this.isHidden = true
    }
    this.isActive = this.active
  },
  methods: {
    checkBarcode() {
      this.form.cardAsDefaultLogin = false
      this.submit()
    },
    checkCard() {
      this.form.barcodeAsDefaultLogin = false
      this.submit()
    },
    submit() {
      this.resetResponseMessages()
      const inputsData = JSON.stringify({
        kurl: this.form.urlKloginMethodOnly,
        usbcard: this.form.cardAsDefaultLogin,
        barcode: this.form.barcodeAsDefaultLogin,
        spassword: this.form.hideStudentKioskPasswordField,
        prefill_kiosk_password: this.form.studentNumberAsKioskPassword,
        default_domain: this.form.defaultDomain
      })
      this.isLoading = true
      this.$store
        .dispatch("modules/updateKioskModule", { option_json: inputsData })
        .then(() => {
          this.setSuccessResponse("Module updated.")
          this.$store
            .dispatch("schools/getActiveModules", {
              clearCacheEntry: true
            })
            .then(() => {
              this.form.urlKloginMethodOnly = Boolean(this.options.kurl)
              this.form.cardAsDefaultLogin = Boolean(this.options.usbcard)
              this.form.barcodeAsDefaultLogin = Boolean(this.options.barcode)
              this.form.hideStudentKioskPasswordField = Boolean(
                this.options.spassword
              )
              this.form.studentNumberAsKioskPassword = Boolean(
                this.options.prefill_kiosk_password
              )
              this.form.defaultDomain = this.options.default_domain
            })
          this.isLoading = false
        })
        .catch((err) => {
          const response = err.response.data
          this.setErrorResponse(response.message, response.errors)
          this.isLoading = false
          this.$store
            .dispatch("schools/getActiveModules", {
              clearCacheEntry: true
            })
            .then(() => {
              this.form.urlKloginMethodOnly = Boolean(this.options.kurl)
              this.form.cardAsDefaultLogin = Boolean(this.options.usbcard)
              this.form.barcodeAsDefaultLogin = Boolean(this.options.barcode)
              this.form.hideStudentKioskPasswordField = Boolean(
                this.options.spassword
              )
              this.form.studentNumberAsKioskPassword = Boolean(
                this.options.prefill_kiosk_password
              )
              this.form.defaultDomain = this.options.default_domain
            })
        })
    },
    openKiossPasswordModal(value) {
      if (value) {
        this.kiossPasswordModal = true
      } else {
        this.submit()
      }
    },
    toggleKiosk(status) {
      const moduleStatus = status
        ? {
            status: 0
          }
        : {
            status: 1
          }
      this.$store
        .dispatch("modules/toggleModule", { id: 3, moduleStatus })
        .then(() => {
          this.isActive = !this.isActive
          this.$store.dispatch("schools/getActiveModules", {
            clearCacheEntry: true
          })
        })
    },
    resetResponseMessages() {
      this.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    },
    setSuccessResponse(message) {
      this.reqResponse = {
        type: "success",
        message: message ? message : "Success!",
        errors: {}
      }
    },
    setErrorResponse(message, errors) {
      this.reqResponse = {
        type: "danger",
        message: message ? message : "Someting went wrong!",
        errors: errors ? errors : {}
      }
    }
  }
}
</script>
